<template>
<div class="setting_main_bg">
  <div class="frie_cont">
    <div class="frie_cont_box">
       <div class="btn_group" v-if="roleBtnArray[0].power">
        <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
      </div>
      <div class="flexAC" :class="roleBtnArray[0].power === true?'ut_top':''">
        <el-input v-model="firestaname"  placeholder="请输入搜索内容" class="contInput"></el-input>
        <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
        <el-button class="settingReset" @click="handleReset">重置</el-button>
      </div>

      <div class="fire_table">
        <el-table
          stripe
          :data="fireList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="单位名称"
            prop="companyname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="微消站名称"
            prop="firestaname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="微消站地址"
            prop="firestaress"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="消防设备"
            prop="firestaequ_num"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="联系人"
            prop="firestauser">
          </el-table-column>
          <el-table-column
            label="电话"
            prop="firestaphone">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
              <el-button type="text" size="small" v-if="roleBtnArray[2].power" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small" v-if="roleBtnArray[1].power" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
  </div>
  <!-- 详情 -->
  <el-dialog title="微消防详情" :visible.sync="detailShow" width="800px" center class="gyDialog unit_addDialog">
    <div class="flexSC">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">微消站基本信息</p>
        </div>
        <p class="udi_p">单位名称：{{ detailInfo.companyname }}</p>
        <p class="udi_p">微消站名称：{{ detailInfo.firestaname }}</p>
        <p class="udi_p">微消站地址：{{ detailInfo.firestaress }}</p>
        <div class="flexCB">
          <p class="udi_s">单位法人：{{ detailInfo.firestauser }}</p>
          <p class="udi_s">法人电话：{{ detailInfo.firestaphone }}</p>
        </div>
      </div>
    </div>

    <div class="flexSC">
      <div class="ud_left">
        <div style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 10px;">
          <div class="udi_k"></div>
          <p class="udi_t">消防设备信息</p>
        </div>
        <div class="udi_c" v-for="(item, index) in detailInfo.firestaequ" :key="index">
          <div class="udi_r"><span>{{item.label}}</span> : <span>{{item.value}}</span></div>
        </div>
      </div>
    </div>
  </el-dialog>

  <!-- 新增 -->
  <el-dialog title="微消站新增" :visible.sync="addFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="addFromCancel">
    <el-form :model="addfrom" :rules="formRules" ref="addFrom">
      <div class="flexCB">
        <el-form-item label="单位名称" class="foItem"  prop="companyid">
<!--          <el-input v-model="addfrom.companyname"  placeholder="请输入单位名称" disabled></el-input>-->
          <el-select v-model="addfrom.companyid" placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%">
            <el-option v-for="item in companyList" :key="item.index"
                       :label="item.companyname"
                       :value="item.companyid">
              {{ item.companyname }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微消站名称"  class="foItem" prop="firestaname">
          <el-input v-model="addfrom.firestaname"  placeholder="请输入微消站名称"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="微消站地址" prop="firestaress">
        <el-input v-model="addfrom.firestaress" placeholder="请选择微消站地址" disabled>
          <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
        </el-input>
      </el-form-item>
      <div class="flexCB">
        <el-form-item label="联系人" class="foitem"  prop="firestauser">
          <el-input v-model="addfrom.firestauser"  placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话"  class="foitem" prop="firestaphone">
          <el-input v-model="addfrom.firestaphone"  placeholder="请输入联系人电话"></el-input>
        </el-form-item>
      </div>
      <div class="adfir">
        <div class="adfir_t">消防设备</div>
        <div class="settingConfirm fir" @click="addEquip">新增</div>
      </div>
      <div style="width: 100%;overflow: hidden;">
        <div class="adfir adfir_eq_box" v-for="(item, index) in addfrom.firestaequ" :key="index">
          <el-form-item label="设备名称" class="foitem"  prop="firestaequ">
            <!-- <el-select v-model="" filterable  allow-create placeholder="请选择" @change="equipSelete(item, index)">
              <div v-for="item in fireEquipList"
                :key="item.staequid">
                  <el-option
                  v-if="!item.select"
                  :label="item.staeqname"
                  :value="item.staeqname">
                </el-option>
              </div>
            </el-select> -->
            <el-autocomplete
              class="inline-input"
              v-model="item.label"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect(item.label, index)">
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="数量"  class="foitem" required>
            <el-input-number v-model="item.value" controls-position="right" @change="handleNumChange" :min="1" ></el-input-number>
          </el-form-item>
          <i class="el-icon-circle-close" style="margin-left: 10px; font-size: 20px; color:red;cursor: pointer;" v-if="addfrom.firestaequ.length > 1" @click="deleteEquip(index)"></i>
        </div>
      </div>
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="addFromCancel">取消</div>
      <div class="settingConfirm" @click="AddFromConfirm">确定</div>
    </div>
  </el-dialog>

  <!-- 编辑 -->
  <el-dialog title="微消站编辑" :visible.sync="editFromShow" width="900px" center class="gyDialog unit_addDialog" :close-on-click-modal="false" :before-close="editFromCancel">
    <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
      <div class="flexCB">
        <el-form-item label="单位名称" class="foItem"  prop="companyname">
          <el-input v-model="editfrom.companyname"  placeholder="请输入单位名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="微消站名称"  class="foItem" prop="firestaname">
          <el-input v-model="editfrom.firestaname"  placeholder="请输入微消站名称"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="微消站地址" prop="firestaress">
        <el-input v-model="editfrom.firestaress" placeholder="请选择微消站地址" disabled>
          <el-button slot="append" icon="el-icon-location" @click="showAddress"></el-button>
<!--          <el-button slot="append" icon="el-icon-location"></el-button>-->
        </el-input>
      </el-form-item>
      <div class="flexCB">
        <el-form-item label="联系人" class="foitem"  prop="firestauser">
          <el-input v-model="editfrom.firestauser"  placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话"  class="foitem" prop="firestaphone">
          <el-input v-model="editfrom.firestaphone"  placeholder="请输入联系人电话"></el-input>
        </el-form-item>
      </div>
      <div class="adfir">
        <div class="adfir_t">消防设备</div>
        <div class="settingConfirm fir" @click="editEquip">新增</div>
      </div>
      <div style="width: 100%;overflow: hidden;">
        <div class="adfir adfir_eq_box" v-for="(item, index) in editfrom.firestaequ" :key="index">
          <el-form-item label="设备名称" prop="firestaequ">
            <!-- <el-select v-model="item.label" filterable  allow-create placeholder="请选择" @change="equipChange(item)">
              <div v-for="item in fireEquipList"
                :key="item.staequid">
                  <el-option
                  v-if="!item.select"
                  :label="item.staeqname"
                  :value="item.staeqname">
                </el-option>
              </div>
            </el-select> -->
            <el-autocomplete
              class="inline-input"
              v-model="item.label"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleEditSelect(item.label, index)">
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="数量" class="foitem" required>
            <el-input-number v-model="item.value" controls-position="right" @change="handleNumEvent" :min="0" ></el-input-number>
          </el-form-item>
          <i class="el-icon-circle-close" style="margin-left: 10px; font-size: 20px; color:red;cursor: pointer;" v-if="editfrom.firestaequ.length > 1" @click="deleteEq(index)"></i>
        </div>
      </div>
    </el-form>
    <div class="flexCE">
      <div class="settingCancel" @click="editFromCancel">取消</div>
      <div class="settingConfirm" @click="editFromConfirm">确定</div>
    </div>
  </el-dialog>

  <!-- 定位 -->
  <el-dialog class="mapDialog" :visible.sync="mapShow" width="600px"  :close-on-click-modal="false" >
    <Address :modify="modifyAdd" :isNoPca="isNoPca" @close="AddressClose" @save="AddressSave"></Address>
  </el-dialog>
</div>
</template>

<script>
import { getfirelist, getfireinfo, fireAdd, fireEdit, fireDelete } from '@/http/api/fire'
import Address from '../../../components/setting/address.vue'
import { isValDateMobile } from '@/utils/validate.js'
import { unitDownQuery } from '@/http/api/unit'
export default {
  name: 'fireStation',
  components: {
    Address
  },
  props: ['roleBtn'],
  data () {
    // const checkRepeatName = (rule, value, callback) => {
    //   this.fireList.forEach(item => {
    //     if (item.companyname === value) {
    //       return callback(new Error('单位名称重复'))
    //     }
    //   })
    //   return callback()
    // }
    // const checkRepeatName1 = (rule, value, callback) => {
    //   console.log(this.fireList)
    //   if (this.fireList.length > 0) {
    //     this.fireList.forEach(item => {
    //       if (item.firestaname === value) {
    //         return callback(new Error('微消站名称重复'))
    //       }
    //     })
    //   }
    //   return callback()
    // }
    const legalMobile = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入联系人电话'))
      }
      if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else {
        callback()
      }
    }
    const checkRepeatName = (rule, value, callback) => {
      this.addfrom.firestaequ.forEach(item => {
        if (item.label === '') {
          return callback(new Error('设备名称不能为空'))
        }
      })
      return callback()
    }
    return {
      page: 1,
      size: 10,
      total: 0,
      fireList: [],
      firestaname: '',
      detailShow: false,
      detailInfo: {},
      equList: [],
      addFromShow: false,
      addfrom: {
        companyid: '',
        firestaname: '',
        firestaress: '',
        firestauser: '',
        firestaphone: '',
        firestalong: '',
        firestalat: '',
        firestaequ: [
          { label: '', value: 1,select: false }
        ]
      },
      formRules: {
        companyid: [{ required: true, message: '请选择单位', trigger: 'blur' }],
        firestaress: [
          { required: true, trigger: 'change', message: '请选择微消站地址' }
        ],
        firestauser: [
          { required: true, message: '请输入联系人', trigger: 'bulr' }
        ],
        firestaphone: [
          { required: true, trigger: 'bulr', validator: legalMobile }
        ],
        firestaequ: [
          { required: true, trigger: 'bulr', message: '设备名称不能为空' },
          { required: true, trigger: 'bulr', validator: checkRepeatName }
        ]
      },
      mapShow: false,
      num: 1,
      fireEquipList: [],
      editFromShow: false,
      editfrom: {},
      editformRules: {
        firestaname: [
          { required: true, trigger: 'blur', message: '微消站名称不能为空' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
          // { validator: checkRepeatName1, message: '微消站名称重复', trigger: 'blur' }
        ],
        firestaress: [
          { required: true, trigger: 'change', message: '请选择微消站地址' }
        ],
        firestauser: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        firestaphone: [
          { required: true, trigger: 'bulr', validator: legalMobile }
        ],
        firestaequ: [
          { required: true, trigger: 'bulr', validator: checkRepeatName }
        ]
      },
      roleBtnArray: [],
      isSuper: true,
      companyList: [],
      modifyAdd: null,
      isNoPca: true
    }
  },
  mounted () {
    this.restaurants = this.getfrieEquip()
  },
  beforeMount () {
    if (this.roleBtn.length !== 0) {
      this.roleBtnArray = this.roleBtn
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    // this.companyname = JSON.parse(window.localStorage.getItem('loginInfo')).companyname
    this.addfrom.companyid = this.companyid
    if (this.companyid === '') {
      this.isSuper = false
    } else { this.isSuper = true }
    // this.getfrieEquip()
    this.DropDown() // 下拉选择框
    this.init()
  },
  watch: {
    // 'this.addfrom.firestaequ.label' () {
    //   if (this.addfrom.firestaequ.label == '') {
    //     this.$message.error('设备名称不能为空')
    //   } else if (this.addfrom.firestaequ.label == val) {
    //     this.$message.error('设备名称不能重复')
    //   }
    //   return
    // }
  },
  methods: {
    DropDown () {
      // 单位选择下拉框
      unitDownQuery().then(res => {
        if (res.status === '1') {
          this.companyList = res.data
        } else {
          this.companyList = []
        }
      })
    },
    init () {
      const params = {
        companyid: this.companyid,
        firestaname: this.firestaname,
        page_index: this.page,
        page_size: this.size
      }
      getfirelist(params).then(res => {
        if (res.status === '1') {
          this.fireList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.fireList = []
          this.total = 0
        }
      })
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    getfrieEquip () {
      const params = {
        staeqname: this.firestaname
      }
      getfireinfo(params).then(res => {
        if (res.status === '1') {
          this.fireEquipList = res.data
          this.fireEquipList.forEach((item) => {
            item.select = false
          })
        } else {
          this.fireEquipList = []
        }
      })
    },
    handleAdd () {
      this.addFromShow = true
      this.getfrieEquip()
    },
    querySearch (queryString, cb) {
      var fireEquipList = []
      this.fireEquipList.forEach(item => {
        if (!item.select) {
          var obj = {
            value: item.staeqname
          }
          fireEquipList.push(obj)
        }
      })
      var results = queryString ? fireEquipList.filter(this.createFilter(queryString)) : fireEquipList
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect (val, index) {
      this.addfrom.firestaequ.forEach((item, i) => {
        if (i != index) {
          if (item.label == val) {
            this.$message.warning('选择重复，请重新选择')
            this.addfrom.firestaequ[index].label = ""
          }
        }
      })
    },
    handleEditSelect (val, index) {
      this.editfrom.firestaequ.forEach((item, i) => {
        if (i != index) {
          if (item.label == val) {
            this.$message.warning('选择重复，请重新选择')
            this.editfrom.firestaequ[index].label = ""
          }
        }
      })
    },
    addEquip () {
      let obj = { label: '', value: 1 }
      this.addfrom.firestaequ.push(obj)
    },
    deleteEquip (index) {
      this.addfrom.firestaequ.splice(index, 1)
    },
    AddFromConfirm () {
      // let flag = false
      // this.addfrom.firestaequ.forEach(item => {
      //   if (item.label == '') {
      //     flag = true
      //   }
      // })
      // if (flag) {
      //   return this.$message.error('设备名称不能为空')
      // }
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.addfrom.companyid,
          firestaname: this.addfrom.firestaname,
          firestaphone: this.addfrom.firestaphone,
          firestauser: this.addfrom.firestauser,
          firestaress: this.addfrom.firestaress,
          firestalong: this.addfrom.firestalong,
          firestalat: this.addfrom.firestalat,
          firestaequ: this.addfrom.firestaequ
        }
        fireAdd(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addfrom.firestaequ = []
      this.addFromShow = false
    },
    // 详情
    handleDetail (row) {
      this.detailShow = true
      this.detailInfo = row
      this.equList = row.firestaequ
    },
    // 编辑
    editEquip () {
      let obj = { label: '', value: 1 }
      this.editfrom.firestaequ.push(obj)
      this.getfrieEquip()
    },
    deleteEq (index) {
      this.editfrom.firestaequ.splice(index, 1)
    },
    handleEdit (row) {
      this.editFromShow = true
      this.getfrieEquip()
      this.editfrom = row
    },
    editFromConfirm () {
      // let flag = false
      // this.editfrom.firestaequ.forEach(item => {
      //   if (item.label === '') {
      //     flag = true
      //   }
      // })
      // if (flag) {
      //   return this.$message.error('设备名称不能为空')
      // }
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.editfrom.companyid,
          firestaname: this.editfrom.firestaname,
          firestaphone: this.editfrom.firestaphone,
          firestauser: this.editfrom.firestauser,
          firestaress: this.editfrom.firestaress,
          firestalong: this.editfrom.firestalong,
          firestalat: this.editfrom.firestalat,
          firestaid: this.editfrom.firestaid,
          firestaequ: this.editfrom.firestaequ
        }
        fireEdit(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    /* 展示地址添加 */
    showAddress () {
      this.mapShow = true
      if (this.addFromShow === true) {
        this.modifyAdd = null
      } else if (this.editFromShow === true) {
        let obj = {
          address: this.editfrom.firestaress,
          longitude: this.editfrom.firestalong,
          latitude: this.editfrom.firestalat
        }
        this.modifyAdd = obj
      }
    },
    AddressClose () {
      this.mapShow = false
    },
    // 定位-保存
    AddressSave (res) {
      this.mapShow = false
      this.modifyAdd = {...res}
      if (this.addFromShow === true) { // 新增
        this.addfrom.firestaress = res.address
        this.addfrom.firestalong = res.longitude
        this.addfrom.firestalat = res.latitude
      } else if (this.editFromShow === true) { // 编辑
        this.editfrom.firestaress = res.address
        this.editfrom.firestalong = res.longitude
        this.editfrom.firestalat = res.latitude
      }
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        fireDelete({ firestaid: row.firestaid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleReset () {
      this.page = 1
      this.firestaname = ''
      this.init()
    },
    handleNumChange () {

    },
    handleNumEvent () {}
  }
}
</script>

<style scoped>
.frie_cont{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.frie_cont_box{
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.ut_top,.fire_table {
  margin-top: 20px;
}
.ud_left{
  width: 520px;
}
.udi_p,.udi_s{
  width: 100%;
  background: #F6F9FF;
  color: #333333;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.udi_s{
  width: 48%;
}
.udi_t {
  color: #333333;
  font-size: 18px;
  font-weight: 800;
  margin-left: 10px;
}
.udi_k {
  width: 4px;
  height: 17px;
  background: #196DF7;
  opacity: 1;
}
.udi_c {
  float: left;
  display: flex;
  width: 160px;
  height: 40px;
  background: #F6F9FF;
  margin-left: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
}
.ud_left{
  width: 700px;
}
.fir {
  width: 50px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}
.adfir {
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
}
.adfir_t {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #000000;

}
.adfir .el-form-item:nth-child(1) {
  margin-right: 10px;
}
.adfir_eq_box:nth-child(even) {
  float: right;
}
.adfir_eq_box:nth-child(odd) {
  float: left;

}
.foItem .el-form-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.foItem .el-form-item__content .el-input-number {
  width: 120px;
}
.flexCB .el-form-item .el-form-item__content .el-input {
  width: 390px!important;
}
</style>
